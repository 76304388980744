<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-card title="지도 미리보기" class="cardClassDetailForm" :height="setImgHeight">
          <template slot="card-button">
            <c-pick-file outlined v-model="file" :dense="true" @getImageData="getImageData">
              <template v-slot:prepend>
                <q-icon name="attach_file"></q-icon>
              </template>
            </c-pick-file>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <q-img :src="mapSrc" fit="scale-down"
                :ratio="4/3"
                style="min-width: 320px; max-width: 700px">
                <!-- <div class="absolute-bottom text-subtitle1 text-center">
                  Caption
                </div> -->
                <template v-slot:loading>
                  <q-spinner-gears color="white" />
                </template>
              </q-img>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="table"
          title="지도 목록"
          tableId="riskHazrd01"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          @getTableHeight="(height) => { this.imgHeight = height }"
        >
          <!-- @rowClick="rowClick" -->
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="add" />
              <c-btn
                v-if="editable"
                :isSubmit="isSave"
                :url="saveUrl"
                :param="grid.data"
                mappingType="PUT"
                label="저장"
                icon="save"
                @beforeAction="saveMap"
                @btnCallback="saveMapCallback"/>
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-btn
                round unelevated
                size="6px"
                color="amber"
                icon="search"
                @click="rowClick(props.row, props.pageIndex)" />
            </template>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-map',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      file: null,
      imgHeight: '',
      mapSrc: require('@/assets/images/no-image2.png'),
      grid: {
        columns: [
          {
            name: 'src',
            field: 'src',
            label: '미리보기',
            align: 'center',
            style: 'width:50px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:100px',
            type: 'plant',
          },
          {
            name: 'mapName',
            field: 'mapName',
            label: '지도명',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:300px',
            type: 'text',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:80px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setImgHeight() {
      let returnText = '';
      returnText = this.imgHeight ? (Number(this.imgHeight.replace('px', '')) - 32) + 'px' : '500px'
      return returnText;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.map.list.url
      this.saveUrl = transactionConfig.sop.swp.map.save.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    rowClick(row) {
      this.mapSrc = require('@/assets/images/sample_map' + (Math.floor(Math.random() * 3) + 1) + '.png');
    },
    add() {
      let date = new Date();
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        sopMapmId: String(date.getTime()),  // 점검항목 일련번호
        plantCd: null,  // 사업장 코드
        mapName: '',  // 지도명
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    getImageData(data) {
      this.mapSrc = data;
    },
    saveMap() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })

            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveMapCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.work-map-chip
  .q-chip__content
    display: none

</style>